import styled from 'styled-components/macro';
export const Container = styled.div`
  width: 100%;
  padding: 8px;
  background: white;
  display: grid;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    flex: 1;
    word-break: break-word;
  }
  h5 {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
  }
  p {
    margin: 0;
    font-size: 10.5px;
  }
`;

export const Button = styled.button<{ active: boolean }>`
  background: transparent;
  padding: 0;
  border: none;
  cursor: pointer;
  transform: rotateX(0deg);
  svg {
    transition: all ease-out 0.3s;
    transform: ${({ active }) =>
      active ? `rotateX(180deg)` : `rotateX(0deg)`};
  }
`;

export const Content = styled.div<{ height: number }>`
  margin-top: 14px;
  transition: max-height 0.3s ease;
  overflow: hidden;
  max-height: ${({ height }) => `${height}px`};
`;
