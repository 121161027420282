import { Button as AntdButton } from 'antd';
import styled from 'styled-components';

import { LARGE_BUTTON, MIDDLE_BUTTON } from './style';

const AbstractButton = styled(AntdButton)`
  ${({ size = 'middle' }) => {
    if (size === 'middle') {
      return MIDDLE_BUTTON;
    }

    if (size === 'large') {
      return LARGE_BUTTON;
    }
  }}
`;

export const Button: typeof AntdButton = styled(AbstractButton).attrs(() => ({
  type: 'default',
}))`
  line-height: unset;
  border: none !important;
  border-radius: 0.5rem;
  box-shadow: none;
  background: ${({ theme }) => theme.palette.GREY_9};
  color: ${({ theme }) => theme.palette.GREY_1};

  &:hover:not(:disabled) {
    background: ${({ theme }) => theme.palette.GREY_8};
    color: ${({ theme }) => theme.palette.GREY_1};
  }

  &:focus:not(:disabled),
  &:active:not(:disabled) {
    background: ${({ theme }) => theme.palette.GREY_7};
    color: ${({ theme }) => theme.palette.GREY_1};
  }

  &:disabled,
  &:disabled:hover {
    color: ${({ theme }) => theme.palette.GREY_4};
  }
`;

export const PrimaryButton: typeof AntdButton = styled(AbstractButton).attrs(
  () => ({
    type: 'primary',
  })
)`
  line-height: unset;
  border: none !important;
  border-radius: 0.5rem;
  box-shadow: none;
  background: ${({ theme }) => theme.palette.PRIMARY};
  color: ${({ theme }) => theme.palette.WHITE};

  &:hover:not(:disabled) {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.15),
        rgba(255, 255, 255, 0.15)
      ),
      ${({ theme }) => theme.palette.PRIMARY};
    color: ${({ theme }) => theme.palette.WHITE};
  }

  &:focus:not(:disabled),
  &:active:not(:disabled) {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)),
      ${({ theme }) => theme.palette.PRIMARY};
    color: ${({ theme }) => theme.palette.WHITE};
  }

  &&&:disabled {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.5),
        rgba(255, 255, 255, 0.5)
      ),
      ${({ theme }) => theme.palette.PRIMARY};
    color: ${({ theme }) => theme.palette.WHITE};
  }
`;

export const DangerButton: typeof AntdButton = styled(AbstractButton).attrs(
  () => ({
    danger: true,
  })
)`
  line-height: unset;
  border: none !important;
  border-radius: 0.5rem;
  box-shadow: none;
  background: ${({ theme }) => theme.palette.RED};
  color: ${({ theme }) => theme.palette.WHITE};

  &:hover:not(:disabled) {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.15),
        rgba(255, 255, 255, 0.15)
      ),
      #f00630;
    color: ${({ theme }) => theme.palette.WHITE};
  }

  &:focus:not(:disabled),
  &:active:not(:disabled) {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)),
      #f00630;
    color: ${({ theme }) => theme.palette.WHITE};
  }

  &&&:disabled {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.5),
        rgba(255, 255, 255, 0.5)
      ),
      #f00630;
    color: ${({ theme }) => theme.palette.WHITE};
  }
`;

export const GhostButton: typeof AntdButton = styled(AbstractButton).attrs(
  () => ({
    type: 'ghost',
  })
)`
  line-height: unset;
  border: none !important;
  border-radius: 0.5rem;
  box-shadow: none;
  background: none;
  color: ${({ theme }) => theme.palette.GREY_1};

  &:hover:not(:disabled) {
    background: none;
    color: ${({ theme }) => theme.palette.GREY_2};
  }

  &:focus:not(:disabled),
  &:active:not(:disabled) {
    background: ${({ theme }) => theme.palette.GREY_9};
    color: ${({ theme }) => theme.palette.GREY_1};
  }

  &&&:disabled {
    background: none;
    color: ${({ theme }) => theme.palette.GREY_5};
  }
`;

export const TertiaryButton: typeof AntdButton = styled(AbstractButton)`
  line-height: unset;
  border: 1px solid ${({ theme }) => theme.palette.GREY_6};
  border-radius: 0.5rem;
  box-shadow: none;
  color: ${({ theme }) => theme.palette.GREY_1};
  background: ${({ theme }) => theme.palette.WHITE};

  &:hover:not(:disabled) {
    border-color: ${({ theme }) => theme.palette.GREY_5};
    background: ${({ theme }) => theme.palette.WHITE};
    color: ${({ theme }) => theme.palette.GREY_1};
  }

  &:focus:not(:disabled),
  &:active:not(:disabled) {
    border-color: ${({ theme }) => theme.palette.GREY_9};
    background: ${({ theme }) => theme.palette.GREY_9};
    color: ${({ theme }) => theme.palette.GREY_1};
  }

  &&&:disabled {
    border-color: ${({ theme }) => theme.palette.GREY_7};
    background: ${({ theme }) => theme.palette.WHITE};
    color: ${({ theme }) => theme.palette.GREY_5};
  }
`;
