import { InputProps } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import React from 'react';
import { AntdTextInput, Container } from './style';

export type ITextInput = {
  size?: SizeType;
} & InputProps;

export const TextInput = React.forwardRef(
  ({ size = 'middle', ...rest }: ITextInput, ref: any) => {
    return (
      <Container size={size}>
        <AntdTextInput {...rest} ref={ref} />
      </Container>
    );
  }
);
