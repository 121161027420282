import React, { ReactNode, useMemo } from 'react';

import { IntlProvider } from 'react-intl';

import * as i18n from '../../i18n';

type Language = Record<string, string>;

type I18nProviderProps = {
  locale?: string;
  defaultLocale?: string;
  languages?: Record<string, Language>;
  children: ReactNode;
};

export function I18nProvider({
  locale = 'en',
  defaultLocale = 'en',
  languages = {},
  ...props
}: I18nProviderProps) {
  const translations = useMemo(() => ({ ...i18n, ...languages }), [languages]);
  const messages = useMemo(() => translations[locale], [locale]);

  return (
    <IntlProvider
      defaultLocale={defaultLocale}
      locale={locale}
      messages={messages}
      {...props}
    />
  );
}
