import React from 'react';
import { useSidebar } from './context';
import styled from 'styled-components/macro';
import { Menu as AntdMenu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DEPTH_RATIO } from './constants';
import { IconName } from '@fortawesome/fontawesome-common-types';

const { Item } = AntdMenu;

type IMenuItemProps = {
  label: string;
  to: string;
  iconName?: IconName;
  level?: number;
  onClickMenuItem: (to: string) => void;
  [key: string]: any;
};

const Container = styled(Item)<{
  collapsed: boolean;
  selected: boolean;
  level: number;
}>`
  cursor: pointer;
  background: transparent !important;
  font-size: 20px;
  padding: 0 14px !important;
  padding-left: ${({ level }) =>
    level !== 1 ? level * DEPTH_RATIO : 14}px !important;
  padding-right: ${({ level }) => level > 1 && 0}px !important;
  user-select: none !important;
  div a {
    color: ${({ theme, selected, level }) =>
      activeColor(selected, theme, level)} !important;
    width: 100%;
    min-width: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    background: ${({ theme, selected, level }) =>
      level < 2 && selected ? theme.palette.PRIMARY : null};
    &:hover {
      background: ${({ collapsed, theme, level, selected }) =>
        getHoverBgColor(collapsed, theme, selected, level)} !important;
      span {
        color: ${({ collapsed, theme, level }) =>
          getHoverFontColor(collapsed, theme, level)} !important;
      }
    }
    svg {
      margin-left: 10.5px;
      width: 1em !important;
    }
    span {
      margin-left: ${({ level }) => getMarginFromLevel(level)}px;
      opacity: 1;
      transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
        min-width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      ${({ collapsed }) =>
        collapsed &&
        `
        max-width: 0;
        display: inline-block;
        opacity: 0;
    `};
    }
  }
`;

const getHoverFontColor = (collapsed, theme, selected) => {
  if (selected && !collapsed) return theme.palette.WHITE;
  return theme.palette.GREY_7;
};

const getHoverBgColor = (collapsed, theme, selected, level) => {
  if (selected && !collapsed && level === 1) return theme.palette.PRIMARY_HOVER;
  if (selected && collapsed && level === 1) return theme.palette.PRIMARY_HOVER;
  return 'transparent';
};

const getMarginFromLevel = (level: number) => {
  if (level === 2) return 30;
  if (level === 3) return 15;
  return 8;
};

const activeColor = (selected: boolean, theme: any, level: number) => {
  if (selected && level === 1) return theme.palette.WHITE;
  if (selected && level === 2) return theme.palette.GREY_7;
  if (selected && level > 2) return theme.palette.GREY_7;
  return theme.palette.GREY_5;
};

const MenuItem: React.FC<IMenuItemProps> = ({
  label,
  iconName,
  level,
  onClickMenuItem,
  ...props
}: IMenuItemProps) => {
  const {
    collapsed,
    selectedMenu,
    sideBarMode,
    setCollapsed,
    setSelectedMenu,
  } = useSidebar();
  const onClickLink = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    const paths = props.to.split('/');
    if (sideBarMode === 'small') {
      setCollapsed(true);
    }
    setSelectedMenu(`/${paths[1]}`);
    onClickMenuItem(props.to);
  };

  return (
    <Container
      title=""
      {...props}
      collapsed={collapsed}
      level={level as number}
      selected={
        (selectedMenu === props.to || selectedMenu.includes(props.to)) &&
        (level as number) <= 10
      }
      data-tip={collapsed ? label : ''}
    >
      <div>
        <a href={props.to} onClick={onClickLink}>
          {iconName && <FontAwesomeIcon icon={['fas', iconName]} />}
          <span>{label}</span>
        </a>
      </div>
    </Container>
  );
};

export default MenuItem;
