import { createContext, useContext } from 'react';
import { ISideBarType } from './constants';
export type ISideBarBehavior = 'over' | 'push';
export type IAnimateState = 'idle' | 'animating';
export type SideBarContext = {
  collapsed: boolean;
  selectedMenu: string;
  sideBarMode: ISideBarType;
  animateState: IAnimateState;
  setCollapsed: (value: boolean) => void;
  setSelectedMenu: (value: string) => void;
};

export const SideBarContext = createContext({
  collapsed: false,
} as SideBarContext);
export const useSidebar = () => useContext(SideBarContext);

SideBarContext.displayName = 'SideBarContext';
