import useWindowSize from '../../hooks/useWindowSize';
import { useState, useEffect, useRef, useCallback } from 'react';
import { useTheme } from 'styled-components';
import {
  DEFAULT_PATH,
  SMALL_WIDTH_SIZE,
  ISideBarType,
  SIDE_BAR_TYPE,
  ANIMATE_STATE,
  DEBOUNCE_TIME,
} from './constants';
import { debounce } from 'lodash';
import { IAnimateState } from './context';

const getSideBarMode = (width: number): ISideBarType => {
  return width < SMALL_WIDTH_SIZE ? SIDE_BAR_TYPE.SMALL : SIDE_BAR_TYPE.LARGE;
};

const useSideMenu = () => {
  const firstRender = useRef(true);
  const windowSize = useWindowSize();
  const sideBarMode = useRef<ISideBarType>(getSideBarMode(windowSize.width));
  const [animateState, setAnimateState] = useState<IAnimateState>(
    ANIMATE_STATE.IDLE
  );

  const [collapsed, setCollapsed] = useState(
    sideBarMode.current === SIDE_BAR_TYPE.LARGE ? false : true
  );
  const [visibleDropdown, setVisibleDropdown] = useState(false);
  const theme: any = useTheme();
  //for set default path
  const [selectedMenu, setSelectedMenu] = useState(DEFAULT_PATH);
  const [selectedSubMenu, setSelectedSubMenu] = useState<string[]>([]);

  useEffect(() => {
    sideBarMode.current = getSideBarMode(windowSize.width);
  }, [windowSize.width]);

  useEffect(() => {
    setSelectedMenu(window.location.pathname);
  }, [window.location.pathname]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      setAnimateState(ANIMATE_STATE.ANIMATING);
      debouncedChangeHandler();
    }
  }, [collapsed]);

  const debouncedChangeHandler = useCallback(
    debounce(() => {
      setAnimateState(ANIMATE_STATE.IDLE);
    }, DEBOUNCE_TIME),
    []
  );

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, []);

  return {
    collapsed,
    visibleDropdown,
    selectedMenu,
    selectedSubMenu,
    windowSize,
    theme,
    sideBarMode: sideBarMode.current,
    animateState,
    setCollapsed,
    setVisibleDropdown,
    setSelectedMenu,
    onOpenChange: (openKeys: any) => {
      if (!collapsed) {
        setSelectedSubMenu(openKeys);
      }
    },
    hideDropdown: () => {
      setVisibleDropdown(false);
    },
    checkCanAccess: (memberRole: string, accessArray: string[]) => {
      return accessArray.includes(memberRole);
    },
  };
};

export default useSideMenu;
