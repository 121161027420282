import React from 'react';

import { ThemeProvider } from '../ThemeProvider';
import { I18nProvider } from '../i18nProvider';
import { theme as defaultTheme } from '../../configs/theme';
import { merge } from 'lodash';
// we put [0] bc we have single arguments named parameters
type Props = Parameters<typeof ThemeProvider>[0] &
  Parameters<typeof I18nProvider>[0];

export function DianaProvider({
  locale = 'en',
  defaultLocale = 'en',
  theme = defaultTheme,
  languages = {},
  children,
}: Props) {
  const mergedTheme = merge({ ...defaultTheme }, theme);

  return (
    <I18nProvider
      defaultLocale={defaultLocale}
      locale={locale}
      languages={languages}
    >
      <ThemeProvider theme={mergedTheme}>{children}</ThemeProvider>
    </I18nProvider>
  );
}
