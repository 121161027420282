import { ChangeEvent, useState } from 'react';

const useDropdownSelectAll = () => {
  const [visible, setVisible] = useState(false);
  const [searchText, setSearchText] = useState<string>('');

  const handleVisible = (value: boolean) => {
    setVisible(value);
  };

  const handleOnSearchTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (searchText !== value) {
      setSearchText(value);
    }
  };

  const handleOnClearSearchText = () => {
    setSearchText('');
  };

  return {
    visible,
    searchText,
    handleVisible,
    handleOnSearchTextChange,
    handleOnClearSearchText,
  };
};

export default useDropdownSelectAll;
