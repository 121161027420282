import styled from 'styled-components/macro';
import { Select as AntdSelect } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { generateHeight } from '../../utils/inputHeight';

export const SelectContainer: typeof AntdSelect = styled(AntdSelect)<{
  size: SizeType;
}>`
  border: 1px solid ${({ theme }) => theme.palette.GREY_7};
  width: 100%;
  border: none;

  .ant-select-selector {
    border-radius: 0.5rem !important;
    padding: 0px 12px;
    height: ${({ size }) => generateHeight(size)} !important;

    .ant-select-selection-item {
      font-weight: 400;
      color: ${({ theme }) => theme.palette.GREY_1};
      display: flex;
      flex-direction: row;
      align-items: center;
      /* padding: 2px 8px 2px 12px; */
      .ant-select-selection-item-remove {
        display: flex;
        svg {
          width: 20px;
          height: 16px;
          color: ${({ theme }) => theme.palette.GREY_5};
        }
      }
    }
    .ant-select-selection-search-input {
      height: 100% !important;
      /* padding: 2px 8px 2px 12px !important; */
    }
    .ant-select-selection-placeholder {
      display: flex;
      align-items: center;
    }
  }

  .ant-select-selector {
    border: 1px solid ${({ theme }) => theme.palette.GREY_7} !important;
    box-shadow: none !important;
  }
`;

export const DropDownContainer = styled.div`
  max-height: 400px;
  .ant-select-item-group {
    padding: 8px 16px;
    height: 28px;
    display: flex;
    align-items: center;
  }
  .ant-select-item-option {
    height: 44px !important;
    padding: 0 16px;
  }
  .ant-select-item.ant-select-item-option:hover {
    background: ${({ theme }) => theme.palette.GREY_9} !important;
  }
  .ant-select-item-option-content {
    display: flex !important;
    align-items: center;
    span {
      flex: 1;
    }
  }
  .ant-select-item-option-state {
    display: flex;
    align-items: center;
  }
`;

export const dropDownStyle = {
  borderRadius: '8px',
  overflow: 'hidden',
  marginTop: '3.5px',
};
