import styled from 'styled-components/macro';

export const Container = styled.div`
  .ant-table {
    border-radius: 0.5rem 0.5rem;
    border: 1px solid #eeeeee;
  }

  .ant-table .ant-table-header table {
    table-layout: auto !important;
  }

  .ant-table-container table > thead .ant-table-cell {
    color: ${({ theme }) => theme.palette.GREY_3};
  }

  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 0.5rem;
  }

  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 0.5rem;
  }

  .ant-table-tbody > tr:last-child > td:first-child {
    border-bottom-left-radius: 0.5rem;
  }

  .ant-table-tbody > tr:last-child > td:last-child {
    border-bottom-right-radius: 0.5rem;
  }

  .ant-pagination-item:hover {
    border-radius: 0.5rem;
  }

  .ant-pagination-item a:hover {
    color: rgba(0, 0, 0, 0.85);
  }

  .ant-pagination-item,
  .ant-pagination-item-link {
    border: none;
    background: transparent;
  }

  .ant-pagination-item-active {
    background: rgba(0, 0, 0, 0.07);
    border-radius: 0.5rem;
    border: none;
  }

  .ant-pagination-item-active a {
    color: ${({ theme }) => theme.palette.GREY_1};
  }

  /* column in table header */
  .ant-table-thead > tr > th.ant-table-column-sort {
    background: #fafafa;
  }

  .ant-table-thead
    .ant-table-selection-column
    .ant-checkbox-indeterminate
    .ant-checkbox-inner::after {
    height: 4px;
    background: ${({ theme }) => theme.palette.PRIMARY};
  }

  /* column in table body */
  .ant-table-tbody > tr > td {
    background: white;
    vertical-align: middle;
  }

  /* hover each row */
  .ant-table-tbody
    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td {
    background: #fafafa;
  }

  .ant-table-tbody > tr:last-child > td {
    border-bottom: none;
  }

  ul.ant-pagination.ant-table-pagination {
    float: none;
    display: flex;
    .ant-pagination-total-text {
      flex: 1;
    }
  }

  .ant-checkbox-wrapper {
    .ant-checkbox .ant-checkbox-inner {
      width: 20px;
      height: 20px;
      border-radius: 4px;
      border: 1px solid #d9d9d9;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      width: 20px;
      height: 20px;
      border-radius: 4px;
      background: ${({ theme }) => theme.palette.PRIMARY};
    }

    /* checked */
    .ant-checkbox-checked .ant-checkbox-inner {
      border: none !important;
    }
    .ant-checkbox-checked::after {
      border: none !important;
    }

    .ant-checkbox-checked .ant-checkbox-inner::after {
      margin-left: 1.5px;
    }

    .ant-checkbox:hover {
      border: none;
    }
  }
`;
