import React, { ChangeEvent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Col, Dropdown, DropDownProps, Row } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { Checkbox } from '../../Checkbox';
import {
  Button,
  Container,
  SelectedItem,
  DropdownOptionLabel,
  PlaceHolder,
  ItemGroup,
  ItemGroupTitle,
  Item,
  ImgCol,
  IconContainer,
  Menu,
  Topbar,
  SelectAllWrap,
  ClearWrap,
  ItemInner,
  DropDownSearch,
  DropdownSearchContainer,
  ItemGroupSeperator,
} from './style';
import _ from 'lodash';
import useDropdownSelectAll from './useDropdownSelectAll';

export type IDropdownSelectAll = {
  data: IDropDownSelectAllData[];
  selected: IDropDownSelectAllData[];
  size?: SizeType;
  allLabel?: string;
  allIcon?: string | null;
  multipleSelectLabel?: string;
  placeHolder: string;
  showSearch?: boolean;
  showSeperator?: boolean;
  onSelectChange: (selected: IDropDownSelectAllData[]) => void;
  onVisibleChange?: (value: boolean) => void;
  onSelectAll: () => void;
  onClearAll: () => void;
  onSelectItem: (item: IDropDownSelectAllData) => void;
} & Omit<DropDownProps, 'overlay'>;

export type IDropdownSelectAllSearch = {
  text: string;
  onClear: () => void;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

export type IDropDownSelectAllData = {
  label: string;
  value: string;
  group?: string;
  icon?: string;
  iconAsText?: boolean;
};

export type IDropdownSelectAllItem = {
  selectedItems: IDropDownSelectAllData[];
  item: IDropDownSelectAllData;
};
export type IDropdownSelectAllItemGroup = {
  group: string;
  selectedItems: IDropDownSelectAllData[];
  items: IDropDownSelectAllData[];
  isLastIndex: boolean;
};

export type IDropdownSelectAllMenu = {
  selectedItems: IDropDownSelectAllData[];
  items: IDropDownSelectAllData[];
};

export const DropdownSelectAll: React.FC<IDropdownSelectAll> = ({
  data = [],
  selected = [],
  size,
  multipleSelectLabel,
  allIcon,
  allLabel = 'All items are selected',
  placeHolder,
  showSearch,
  showSeperator = true,
  onSelectChange,
  onVisibleChange,
  onSelectAll,
  onClearAll,
  onSelectItem,
  ...rest
}: IDropdownSelectAll) => {
  const {
    visible,
    searchText,
    handleVisible,
    handleOnSearchTextChange,
    handleOnClearSearchText,
  } = useDropdownSelectAll();

  const _renderTopBar = () => {
    return (
      <Topbar>
        <SelectAllWrap onClick={onSelectAll}>Select All</SelectAllWrap>
        <ClearWrap onClick={onClearAll}>Clear</ClearWrap>
      </Topbar>
    );
  };
  const _renderItem = ({ selectedItems, item }: IDropdownSelectAllItem) => {
    const checked = selectedItems.some((si) => _.isEqual(si, item));
    return (
      <Item
        key={`dropdown-item-${item.label}`}
        data-testid="dropdown-item"
        onClick={() => onSelectItem(item)}
      >
        <Row gutter={[16, 0]} justify={'space-between'}>
          <Col>
            <ItemInner>
              {item.icon && (
                <ImgCol>
                  {item.iconAsText ? (
                    <Avatar>{item.icon[0]}</Avatar>
                  ) : (
                    <IconContainer>
                      <img src={item.icon} />
                    </IconContainer>
                  )}
                </ImgCol>
              )}
              <Col>
                <DropdownOptionLabel ellipsis>{item.label}</DropdownOptionLabel>
              </Col>
            </ItemInner>
          </Col>
          <Col>
            <Checkbox checked={checked} />
          </Col>
        </Row>
      </Item>
    );
  };

  const _renderItemGroup = ({
    group,
    selectedItems,
    items,
    isLastIndex,
  }: IDropdownSelectAllItemGroup) => {
    const filteredItems = items.filter(
      (item) => item.group && item.group === group
    );
    return (
      <>
        <ItemGroup
          key={`dropdown-group-${group}`}
          title={<ItemGroupTitle type="secondary">{group}</ItemGroupTitle>}
        >
          {filteredItems.map((item) => {
            return _renderItem({ selectedItems, item });
          })}
          {!isLastIndex && showSeperator && <ItemGroupSeperator />}
        </ItemGroup>
      </>
    );
  };

  const _renderDropdownSearch = ({
    text,
    onChange,
    onClear,
  }: IDropdownSelectAllSearch) => {
    return (
      <DropdownSearchContainer>
        <DropDownSearch value={text} onChange={onChange} onClear={onClear} />
      </DropdownSearchContainer>
    );
  };

  const _renderMenu = ({ selectedItems, items }: IDropdownSelectAllMenu) => {
    const filteredItems = searchText
      ? items.filter((item) => {
          const includedInLabel = item.label
            .toLowerCase()
            .includes(searchText.toLowerCase());
          const includedInValue = item.label
            .toLowerCase()
            .includes(searchText.toLowerCase());
          return includedInLabel || includedInValue;
        })
      : items;
    const uniqueGroups = _.uniq(
      filteredItems.filter((item) => item.group).map((item) => item.group)
    );
    const ungroupItems = filteredItems.filter((item) => !item.group);
    const groupedMenuItems =
      uniqueGroups.length > 0 &&
      uniqueGroups.map((group, index) => {
        return (
          group &&
          _renderItemGroup({
            group,
            selectedItems,
            items: filteredItems,
            isLastIndex: index === uniqueGroups.length - 1,
          })
        );
      });
    const ungroupedMenuItems =
      ungroupItems.length > 0 &&
      ungroupItems.map((item) => {
        return _renderItem({ selectedItems, item });
      });
    return (
      <Menu>
        {showSearch &&
          _renderDropdownSearch({
            text: searchText,
            onChange: handleOnSearchTextChange,
            onClear: handleOnClearSearchText,
          })}
        {_renderTopBar()}
        {groupedMenuItems}
        {uniqueGroups.length > 0 && ungroupItems.length > 0 ? (
          <ItemGroup
            title={<ItemGroupTitle type="secondary">UNGROUP</ItemGroupTitle>}
          >
            {ungroupedMenuItems}
          </ItemGroup>
        ) : (
          ungroupedMenuItems
        )}
      </Menu>
    );
  };

  const _renderDropdownLabel = (
    menuList: IDropDownSelectAllData[],
    selectedItem: IDropDownSelectAllData[]
  ) => {
    if (selectedItem.length > 1 && selectedItem.length !== menuList.length) {
      return (
        <DropdownOptionLabel ellipsis>
          {selectedItem.length} {multipleSelectLabel ?? 'items selected'}
        </DropdownOptionLabel>
      );
    }
    if (selectedItem.length === 1) {
      return (
        <>
          {selectedItem[0] && selectedItem[0].icon && (
            <img src={selectedItem[0].icon} />
          )}
          <DropdownOptionLabel ellipsis>
            {selectedItem[0].label}
          </DropdownOptionLabel>
        </>
      );
    }

    if (selectedItem.length === menuList.length) {
      return (
        <>
          {allIcon && <img src={allIcon} />}
          <DropdownOptionLabel ellipsis>{allLabel}</DropdownOptionLabel>
        </>
      );
    }

    return <PlaceHolder>{placeHolder}</PlaceHolder>;
  };

  return (
    <Container size={size}>
      <Dropdown
        {...rest}
        overlay={_renderMenu({
          selectedItems: selected,
          items: data,
        })}
        data-testid="dropdown-select-all-wrap"
        onVisibleChange={(value: boolean) => {
          if (onVisibleChange) {
            onVisibleChange(value);
          }
          handleVisible(value);
        }}
        visible={visible}
      >
        <Button data-testid="dropdown-apply-btn">
          <SelectedItem>{_renderDropdownLabel(data, selected)}</SelectedItem>
          <div>
            <FontAwesomeIcon icon={['fas', 'chevron-down']} />
          </div>
        </Button>
      </Dropdown>
    </Container>
  );
};
