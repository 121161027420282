import React from 'react';
import styled from 'styled-components/macro';
import { Player } from '@lottiefiles/react-lottie-player';

import lottieJson from './lottie.json';

export type ILoadingCircle = {
  width?: number;
  height?: number;
};

const Container = styled(Player)<{ width: number; height: number }>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`;

export const LoadingCircle: React.FC<ILoadingCircle> = ({
  width = 100,
  height = 50,
}: ILoadingCircle) => {
  return (
    <Container
      id="loading-indicator"
      width={width}
      height={height}
      autoplay
      loop
      src={lottieJson}
    ></Container>
  );
};
