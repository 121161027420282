import { ISideBarBehavior } from './context';

export type ISideBarType = 'small' | 'large';

export const enum SIDE_BAR_TYPE {
  SMALL = 'small',
  LARGE = 'large',
}

export const enum ANIMATE_STATE {
  IDLE = 'idle',
  ANIMATING = 'animating',
}
export const DEBOUNCE_TIME = 200;
export const TRUNCATE_NUMBER = 15;
export const DEPTH_RATIO = 5;
export const DEFAULT_PATH = '/recents';
export const SMALL_WIDTH_SIZE = 1512;
export const AVATAR = {
  width: 32,
  height: 32,
};
export const TOOLTIP = {
  delayShow: 310,
  left: 70,
};

export const AGENT_STATUS = [
  {
    value: 'on_duty',
    label: 'Online',
    color: '#29CB72',
  },
  {
    value: 'busy',
    label: 'Busy',
    color: '#F72C40',
  },
  {
    value: 'unavailable',
    label: 'Unavailable',
    color: '#757575',
  },
];
