import styled from 'styled-components/macro';
import { Input as AntdInput } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { generateHeight } from '../../utils/inputHeight';

export const Input = styled(AntdInput)<{ size: SizeType }>`
  border-radius: 7px;
  background: ${({ theme }) => theme.palette.WHITE};
  height: ${({ size }) => generateHeight(size)};
  :hover {
    border-color: #d9d9d9 !important;
    box-shadow: none;
  }

  &.ant-input-affix-wrapper-focused {
    border-color: ${({ theme }) => theme.palette.SECONDARY_BLUE} !important;
    .ant-input-prefix svg {
      color: ${({ theme }) => theme.palette.SECONDARY_BLUE};
    }
  }

  input {
    background: ${({ theme }) => theme.palette.WHITE};
  }
  svg {
    transition: all 0.3s;
    color: ${({ theme }) => theme.palette.GREY_5};
  }
  .ant-input-suffix {
    cursor: pointer;
  }
`;
