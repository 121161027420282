import React from 'react';
import styled from 'styled-components/macro';
import { Menu as AntdMenu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSidebar } from './context';
import { DEPTH_RATIO } from './constants';
import { IconName } from '@fortawesome/fontawesome-svg-core';
const { SubMenu: AntdSubMenu } = AntdMenu;

type ISubMenuProps = {
  label: string;
  iconName?: IconName;
  to?: string;
  level?: number;
  [key: string]: any;
};

const Container = styled(AntdSubMenu)<{
  collapsed: boolean;
  level: number;
  selected: boolean;
  [key: string]: any;
}>`
  color: ${({ theme }) => theme.palette.GREY_5} !important;
  cursor: pointer;
  background: transparent !important;
  font-size: 20.8px;
  padding: 0 20px !important;
  padding-left: ${({ level }) =>
    level !== 1 ? level * DEPTH_RATIO : 14}px !important;
  padding-right: ${({ level }) => (level > 1 ? 0 : 14)}px !important;
  user-select: none !important;
  ul {
    background: transparent !important;
  }
  & > .ant-menu-submenu-title {
    width: 100%;
    min-width: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 0 !important;
    color: ${({ theme, selected, level }) =>
      selected && level === 1
        ? theme.palette.WHITE
        : selected && level === 2
        ? theme.palette.PRIMARY
        : theme.palette.GREY_5} !important;
    background: ${({ theme, selected, level }) =>
      level === 1 && selected
        ? theme.palette.PRIMARY
        : 'transparent'} !important;
    &:hover {
      background: ${({ theme, selected }) => !selected && theme.palette.GREY_2};
      color: ${({ theme, level }) =>
        level >= 3 ? theme.palette.WHITE : theme.palette.GREY_5};
    }
    svg {
      width: 20px;
      margin-left: 10.5px;
    }
    span {
      margin-left: ${({ level }) => getMarginFromLevel(level)}px;
      opacity: 1;
      transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
        min-width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
      ${({ collapsed }) =>
        collapsed &&
        `
        max-width: 0;
        display: inline-block;
        opacity: 0;
    `};
    }
    i {
      color: ${({ theme }) => theme.palette.WHITE} !important;
      transition: opacity 0.3s;
      opacity: 1;
      display: block;
      ${({ collapsed }) =>
        collapsed &&
        `
        display: block !important;
        opacity: 0;
      `};
    }
  }
`;

const getMarginFromLevel = (level: number) => {
  if (level === 2) return 30;
  return 8;
};

const SubMenu: React.FC<ISubMenuProps> = ({
  label,
  iconName,
  level,
  children,
  ...props
}: ISubMenuProps) => {
  const { collapsed, selectedMenu, setCollapsed } = useSidebar();
  const handleClickIcon = () => {
    if (collapsed) {
      setCollapsed(false);
    }
  };
  return (
    <Container
      onTitleClick={handleClickIcon}
      title={<span>{label}</span>}
      onClick={handleClickIcon}
      icon={iconName && <FontAwesomeIcon icon={['fas', iconName]} />}
      collapsed={collapsed}
      data-tip={collapsed ? label : ''}
      level={level as number}
      selected={
        `/${selectedMenu.split('/')[1]}` === props.to && (level as number) === 1
      }
      {...props}
    >
      {children}
    </Container>
  );
};

export default SubMenu;
