import React from 'react';
import { FormItemProps } from 'antd';
import { AntdFormItem } from './style';

export type IFormItem = FormItemProps;

export const FormItem: React.FC<IFormItem> = ({
  children,
  ...rest
}: IFormItem) => {
  return <AntdFormItem {...rest}>{children}</AntdFormItem>;
};
