import styled from 'styled-components/macro';
import {
  Menu as AntdMenu,
  Divider as AntdDivider,
  Dropdown as AntdDropdown,
} from 'antd';
import { IAnimateState, ISideBarBehavior } from './context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ShadowMenu = styled.div<{
  windowwidth: number;
  selectedMenu: string;
  collapsed: boolean;
  behavior?: ISideBarBehavior;
}>`
  height: 100vh;
  transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;

  ${({ windowwidth, selectedMenu, collapsed, behavior }) => {
    return `
      width: ${manageShadowWidth(
        windowwidth,
        selectedMenu,
        collapsed,
        behavior
      )}
    `;
  }};
`;

export const SettingIcon = styled(FontAwesomeIcon)`
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    min-width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
  color: ${({ theme }) => theme.palette.GREY_6};
  font-size: 18px;
  width: 100% !important;
  cursor: pointer;
`;

export const UserSettingContainer = styled.div`
  width: 100%;
  div:nth-child(1) {
    display: flex;
    align-items: center;
  }
`;

export const UserProfileContainer = styled.div`
  cursor: pointer;
`;

export const UserName = styled.span`
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    min-width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
`;

export const Menu = styled(AntdMenu)<{
  windowwidth: number;
  selectedMenu: string;
  animatestate: IAnimateState;
  inlineCollapsed: boolean;
}>`
  display: flex;
  flex-direction: column;
  background: #212121;
  border-right: none;
  height: 100%;
  width: ${({ inlineCollapsed }) =>
    inlineCollapsed ? '68px' : '240px'} !important;
  z-index: 999;
  position: absolute;
  top: 0;
  //member avatar
  > :last-child {
    margin: auto 18px 24px 18px;
    ${UserSettingContainer} {
      display: ${({ inlineCollapsed, animatestate }) =>
        getSettingState(inlineCollapsed, animatestate)};
      align-items: center;
      span:nth-child(2) {
        color: ${({ theme }) => theme.palette.WHITE};
        max-width: ${({ inlineCollapsed }) => (inlineCollapsed ? '0' : '100%')};
        overflow: ${({ inlineCollapsed }) =>
          inlineCollapsed ? 'hidden' : 'initial'};
        font-size: 14px;
        margin-left: ${({ inlineCollapsed }) =>
          inlineCollapsed ? '0' : '4px'};
        font-weight: 500;
      }
    }

    ${UserProfileContainer} {
      overflow: hidden;
      ${({ inlineCollapsed }) => {
        return {
          width: inlineCollapsed ? '40px' : '180px',
        };
      }}
    }

    ${SettingIcon} {
      width: 31.5px !important;
      display: ${({ animatestate }) =>
        animatestate === 'animating' ? 'none' : 'block'} !important;
      margin: ${({ inlineCollapsed }) =>
        inlineCollapsed ? '16px auto 0' : '0 0px 0 8px'};
    }
    ${UserName} {
      opacity: ${({ inlineCollapsed }) => (inlineCollapsed ? 0 : 1)};
    }
  }

  span {
    font-size: 14px !important;
  }
  .ant-menu-item::after {
    border-right: transparent !important;
  }
  .ant-menu-submenu-arrow {
    font-size: 20px;
    color: ${({ theme }) => theme.palette.WHITE};
  }
`;

export const Divider = styled(AntdDivider)`
  background: ${({ theme }) => theme.palette.GREY_5};
`;

export const MemberMenu = styled(AntdMenu)`
  width: 300px;
  border-radius: 12px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%) !important;
  overflow: auto;
  & li:hover {
    background: ${({ theme }) => theme.palette.GREY_9};
    color: initial;
  }
`;

export const MemberNestMenuItem = styled(MemberMenu.Item)<{
  circleColor?: string;
}>`
  height: 44px;
  display: flex !important;
  align-items: center;
  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.palette.GREY_7};
  }
  > div:nth-child(1) {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: ${({ circleColor }) => circleColor};
    margin-right: 8px;
  }
`;

export const MemberMenuItem = styled(MemberMenu.Item)<{
  circleColor?: string;
  alignMent?: string;
  iconColor?: string;
  labelColor?: string;
}>`
  height: 44px;
  display: flex !important;
  align-items: center;
  justify-content: ${({ alignMent }) => (alignMent ? alignMent : 'flex-start')};
  & :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.palette.GREY_7};
  }
  & > div:nth-child(1) {
    margin-right: 8px;
    svg {
      color: ${({ iconColor, theme }) => iconColor ?? theme.palette.GREY_1};
    }
  }

  & > div:nth-child(2) span {
    color: ${({ theme, labelColor }) => labelColor ?? theme.palette.GREY_1};
  }
`;

export const MemberSubMenu = styled(MemberMenu.SubMenu)`
  .ant-dropdown-menu-submenu-title {
    height: 44px;
    display: flex !important;
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => theme.palette.GREY_7};
  }
`;

export const MemberProfile = styled.div`
  padding: 7.5px 16px;
  display: flex;
  column-gap: 8px;
  height: 56px;
  align-items: center;
  & div:nth-child(2) {
    & div:nth-child(1) span {
      color: ${({ theme }) => theme.palette.GREY_1};
      font-weight: 500;
    }
    & div:nth-child(2) span {
      color: ${({ theme }) => theme.palette.GREY_5};
      font-size: 12px;
      font-weight: 500;
    }
  }
  border-bottom: 1px solid ${({ theme }) => theme.palette.GREY_7};
`;

export const Dropdown: typeof AntdDropdown = styled(AntdDropdown)``;

const manageShadowWidth = (
  windowWidth: number,
  selectedMenu: string,
  collapsed: boolean,
  behavior?: ISideBarBehavior
) => {
  const selectedMenuLength = selectedMenu.split('/').length;
  if (behavior === 'over') return '68px';
  if (behavior === 'push' && !collapsed) return '240px';
  if (windowWidth > 1280 && !collapsed && selectedMenuLength < 3)
    return '240px';
  if (windowWidth > 1280 && !collapsed && selectedMenuLength > 3) return '68px';
  return '68px';
};

const getSettingState = (collapsed: boolean, animateState: IAnimateState) => {
  // ui going to collapse but still animating
  if (animateState === 'animating' && collapsed) return 'flex';
  // ui already collapsed
  if (animateState === 'idle' && collapsed) return 'block';
  return 'flex';
};
