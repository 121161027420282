import React from 'react';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { SelectContainer, DropDownContainer, dropDownStyle } from './style';

export type IOption = {
  value: string;
  label: string;
};

export type ISelect = {
  options: IOption[];
  size?: SizeType;
  [key: string]: any;
};

export const Select: React.FC<ISelect> = ({
  size = 'middle',
  options,
  ...rest
}: ISelect) => {
  const _renderDropDown = (
    menu: React.ReactElement<any, string | React.JSXElementConstructor<any>>
  ) => {
    return (
      <>
        <DropDownContainer data-wrapper="amity-wrapper">
          {menu}
        </DropDownContainer>
      </>
    );
  };

  return (
    <SelectContainer
      {...rest}
      data-testid="antd-select"
      dropdownStyle={dropDownStyle}
      dropdownRender={_renderDropDown}
      size={size}
    >
      {options.map(({ label, value }: IOption) => {
        return (
          <SelectContainer.Option key={value} value={value}>
            <span>{label}</span>
          </SelectContainer.Option>
        );
      })}
    </SelectContainer>
  );
};
