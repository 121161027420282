import styled, { css } from 'styled-components';

export const defaultStyle = css<{ align?: 'string' }>`
  width: 100%;
  border-radius: 0.5rem;
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: ${({ align = 'center' }) => align};
  align-items: center;
  color: ${({ theme }) => theme.palette.BLACK};

  .anticon {
    font-size: 1rem;
    margin: 0.125rem 0.25rem 0 0;
  }
`;

// Success Toast
export const successToast = css`
  ${defaultStyle};
  background-color: ${({ theme }) => theme.palette.GREEN_LIGHT};
  border: 1px solid ${({ theme }) => theme.palette.GREEN};
  .anticon {
    color: ${({ theme }) => theme.palette.GREEN};
  }
`;

export const SuccessToastContainer = styled.div<{ align: string }>`
  ${successToast}
  justify-content: ${({ align }) => align};
`;

export const SuccessIconWrap = styled.div`
  margin-right: 12px;
  color: ${({ theme }) => theme.palette.GREEN};
  svg {
    font-size: 18px;
  }
`;

// Danger Toast
export const dangerToast = css`
  ${defaultStyle};
  background-color: ${({ theme }) => theme.palette.RED_LIGHT};
  border: 1px solid ${({ theme }) => theme.palette.RED};
  .anticon {
    color: ${({ theme }) => theme.palette.RED};
  }
`;

export const DangerToastContainer = styled.div<{ align: string }>`
  ${dangerToast}
  justify-content: ${({ align }) => align};
`;

export const DangerIconWrap = styled.div`
  margin-right: 12px;
  color: ${({ theme }) => theme.palette.RED};
  svg {
    font-size: 18px;
  }
`;

// Warning Toast
export const warningToast = css`
  ${defaultStyle}
  background-color: ${({ theme }) => theme.palette.YELLOW_LIGHT};
  border: 1px solid ${({ theme }) => theme.palette.YELLOW};
  .anticon {
    color: ${({ theme }) => theme.palette.YELLOW};
  }
`;

export const WarningToastContainer = styled.div<{ align: string }>`
  ${warningToast}
  justify-content: ${({ align }) => align};
`;

export const WarningIconWrap = styled.div`
  margin-right: 12px;
  color: ${({ theme }) => theme.palette.YELLOW};
  svg {
    font-size: 18px;
  }
`;

//Info Toast
export const infoToast = css`
  ${defaultStyle}
  background-color: ${({ theme }) => theme.palette.BLUE_LIGHT};
  border: 1px solid ${({ theme }) => theme.palette.BLUE};
  .anticon {
    color: ${({ theme }) => theme.palette.BLUE};
  }
`;

export const InfoToastContainer = styled.div<{ align: string }>`
  ${infoToast}
  justify-content: ${({ align }) => align};
`;

export const InfoIconWrap = styled.div`
  margin-right: 12px;
  color: ${({ theme }) => theme.palette.BLUE};
  svg {
    font-size: 18px;
  }
`;

//use in external
export const ToastWrap = styled.div`
  margin-bottom: 16px;
`;
