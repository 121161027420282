import React from 'react';
import { Avatar } from '../Avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IMember } from '../../types/member';
import { getSplitName, truncateStr } from '../../utils';
import { Typography } from 'antd';
import ReactTooltip from 'react-tooltip';
import Burger from './Burger';
import {
  AGENT_STATUS,
  AVATAR,
  ISideBarType,
  TOOLTIP,
  TRUNCATE_NUMBER,
} from './constants';
import { SideBarContext } from './context';
import ItemGroup from './ItemGroup';
import MenuItem from './MenuItem';
import {
  Divider,
  Dropdown,
  MemberMenu,
  MemberMenuItem,
  MemberNestMenuItem,
  MemberProfile,
  MemberSubMenu,
  Menu,
  SettingIcon,
  ShadowMenu,
  UserSettingContainer,
  UserName,
  UserProfileContainer,
} from './style';
import './style.css';
import SubMenu from './SubMenu';
import useSideMenu from './useSideMenu';
import { IconName } from '@fortawesome/fontawesome-svg-core';

export type ISideBar = {
  label: string;
  iconName?: IconName;
  path: string;
  children?: ISubMenu[];
  pageName: string;
};

export type ISubMenu = {
  label: string;
  groupName?: string;
  path: string;
  children?: ISubMenu[];
  pageName: string;
};

export type ISideMenu = {
  sideBarData: ISideBar[];
  member: Partial<IMember>;
  showSubMenu: boolean;
  onClickUserProfile?: () => void;
  onClickMenuItem: (to: string) => void;
  onLogout: () => void;
  onChangeMemberStatus: (status: string) => void;
};

const getSideBarBehavior = (sideBarMode: ISideBarType) => {
  return sideBarMode === 'small' ? 'over' : 'push';
};

export const SideMenu: React.FC<ISideMenu> = ({
  sideBarData,
  member,
  showSubMenu,
  onClickUserProfile,
  onClickMenuItem,
  onLogout,
  onChangeMemberStatus,
}) => {
  const {
    collapsed,
    visibleDropdown,
    selectedMenu,
    selectedSubMenu,
    windowSize,
    theme,
    sideBarMode,
    animateState,
    setCollapsed,
    setVisibleDropdown,
    setSelectedMenu,
    onOpenChange,
    hideDropdown,
  } = useSideMenu();

  const _renderSubMenu = (item: ISideBar) => {
    return (
      <SubMenu
        key={item.path}
        label={item.label}
        iconName={item.iconName}
        to={item.path}
      >
        {item.children?.map((subItem: ISubMenu, index: number) => {
          const isLastItem = index === (item.children as []).length - 1;
          if (subItem.groupName) {
            return _renderItemGroup(subItem, !isLastItem);
          }

          if (subItem.children) {
            return _renderSubMenu(subItem);
          }

          return (
            <MenuItem
              key={subItem.path}
              label={subItem.label}
              to={subItem.path}
              onClickMenuItem={onClickMenuItem}
            />
          );
        })}
      </SubMenu>
    );
  };

  const _renderItemGroup = (subMenulv1: ISubMenu, showDivider: boolean) => {
    if (subMenulv1.groupName && subMenulv1.children?.length) {
      return (
        <ItemGroup title={subMenulv1.groupName || ''}>
          <SubMenu
            key={subMenulv1.path}
            label={subMenulv1.label}
            to={subMenulv1.path}
          >
            {subMenulv1.children.map((subMenulv2) => {
              if (subMenulv2.children) {
                return (
                  <SubMenu
                    key={subMenulv2.path}
                    label={subMenulv2.label}
                    to={subMenulv2.path}
                  >
                    {subMenulv2.children.map((subMenulv3) => {
                      return (
                        <MenuItem
                          key={subMenulv3.path}
                          label={subMenulv3.label}
                          to={subMenulv3.path}
                          onClickMenuItem={onClickMenuItem}
                        />
                      );
                    })}
                  </SubMenu>
                );
              }
              return (
                <MenuItem
                  key={subMenulv2.path}
                  label={subMenulv2.label}
                  to={subMenulv2.path}
                  onClickMenuItem={onClickMenuItem}
                  s
                />
              );
            })}
          </SubMenu>
          {showDivider && <Divider />}
        </ItemGroup>
      );
    }
  };

  const _renderMemberMenu = () => {
    return (
      <MemberMenu>
        <MemberProfile>
          <div>
            <Avatar
              width={AVATAR.width}
              height={AVATAR.height}
              name={getSplitName(member.username)}
              memberStatus={member.status}
            />
          </div>
          <div>
            <div>
              <Typography.Text>{member.username}</Typography.Text>
            </div>
            <div>
              {member.email && (
                <Typography.Text>{member.email}</Typography.Text>
              )}
            </div>
          </div>
        </MemberProfile>
        {showSubMenu && (
          <MemberSubMenu
            title={<div>Status</div>}
            popupClassName="member-submenu-popup"
          >
            {AGENT_STATUS.map((item) => (
              <MemberNestMenuItem
                key={item.value}
                circleColor={item.color}
                onClick={() => {
                  onChangeMemberStatus(item.value);
                  hideDropdown();
                }}
              >
                <div />
                <div>
                  <Typography.Text>{item.label}</Typography.Text>
                </div>
              </MemberNestMenuItem>
            ))}
          </MemberSubMenu>
        )}

        <MemberMenuItem
          onClick={() => {
            onLogout();
            setVisibleDropdown(false);
          }}
          iconColor={theme.palette.RED}
          labelColor={theme.palette.RED}
        >
          <div>
            <FontAwesomeIcon icon={['fas', 'sign-out-alt']} />
          </div>
          <div>
            <Typography.Text>Log out</Typography.Text>
          </div>
        </MemberMenuItem>
      </MemberMenu>
    );
  };

  return (
    <React.StrictMode>
      <SideBarContext.Provider
        value={{
          collapsed,
          selectedMenu,
          sideBarMode,
          animateState,
          setSelectedMenu,
          setCollapsed,
        }}
      >
        {/* to prevent render tooltip with empty array it will not show tooltip if array is empty and after that it has value */}
        {collapsed && sideBarData.length > 0 && (
          <ReactTooltip
            place="right"
            effect="solid"
            className="sidebar-tooltip"
            overridePosition={({ top }) => {
              return {
                left: TOOLTIP.left,
                top,
              };
            }}
          />
        )}

        <ShadowMenu
          collapsed={collapsed}
          selectedMenu={selectedMenu}
          windowwidth={windowSize.width || window.screen.width}
          behavior={getSideBarBehavior(sideBarMode)}
        />
        <Menu
          mode="inline"
          selectedMenu={selectedMenu}
          inlineCollapsed={collapsed}
          animatestate={animateState}
          windowwidth={windowSize.width || window.screen.width}
          triggerSubMenuAction="click"
          onOpenChange={onOpenChange}
          openKeys={selectedSubMenu}
        >
          <Burger />
          {sideBarData.map((item: ISideBar) => {
            if (item.children) {
              return _renderSubMenu(item);
            }

            return (
              <MenuItem
                key={item.path}
                label={item.label}
                iconName={item.iconName}
                to={item.path}
                onClickMenuItem={onClickMenuItem}
              />
            );
          })}
          <li>
            <UserSettingContainer>
              <UserProfileContainer onClick={onClickUserProfile}>
                <Avatar
                  name={getSplitName(member.username)}
                  width={AVATAR.width}
                  height={AVATAR.height}
                  memberStatus={member.status}
                />
                <UserName>
                  {truncateStr(member.username, TRUNCATE_NUMBER)}
                </UserName>
              </UserProfileContainer>
              <Dropdown
                visible={visibleDropdown}
                onVisibleChange={(flag) => setVisibleDropdown(flag)}
                placement="topLeft"
                trigger={['click']}
                overlay={_renderMemberMenu()}
              >
                <SettingIcon
                  icon={['fas', 'cog']}
                  onClick={() => {
                    setVisibleDropdown(!visibleDropdown);
                  }}
                />
              </Dropdown>
            </UserSettingContainer>
          </li>
        </Menu>
      </SideBarContext.Provider>
    </React.StrictMode>
  );
};
