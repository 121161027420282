import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from './style';
import { InputProps } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

export type ISearchInput = {
  value: string;
  size?: SizeType;
  onClear: () => void;
} & InputProps;

export const SearchInput = React.forwardRef(
  (
    {
      value,
      size = 'middle',
      onClear,
      onChange,
      placeholder,
      ...rest
    }: ISearchInput,
    ref: any
  ) => {
    return (
      <Input
        {...rest}
        size={size}
        ref={ref}
        value={value}
        prefix={<FontAwesomeIcon icon={['fas', 'search']} />}
        suffix={
          value && (
            <FontAwesomeIcon icon={['fas', 'times-circle']} onClick={onClear} />
          )
        }
        onChange={onChange}
        placeholder={placeholder ?? 'Search'}
      />
    );
  }
);
