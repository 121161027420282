import React, { ReactNode } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { theme as defaultTheme } from '../../configs/theme';

type ThemeProviderProps = {
  theme?: typeof defaultTheme;
  children: ReactNode;
};

export function ThemeProvider({ theme, ...props }: ThemeProviderProps) {
  return <StyledThemeProvider {...props} theme={theme} />;
}
