import React, { useCallback } from 'react';
import Line from '../../assets/images/line-icon.svg';
import Facebook from '../../assets/images/fb-icon.svg';
import AmityLogo from '../../assets/images/amity-logo-with-bg.svg';
import { Container, CircleStatus, AvatarItem } from './style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { matchFirstLetterToColor } from '../../utils/color';

export type ChannelType = 'line' | 'facebook' | 'amity-chat';

export type IAvatar = {
  img?: string;
  name?: string;
  channelType?: ChannelType;
  memberStatus?: string;
  width?: number;
  height?: number;
  isDefaultAvatar?: boolean;
  fontSize?: number;
};

export const Avatar: React.FC<IAvatar> = ({
  img,
  name,
  channelType,
  memberStatus,
  width = 50,
  height = 50,
  fontSize,
}: IAvatar) => {
  const getSplitName = useCallback((name: string) => {
    const splitName = name.split(' ');
    if (splitName.length > 1) {
      return `${splitName[0].charAt(0)}${splitName[splitName.length - 1].charAt(
        0
      )}`;
    }

    return `${splitName[0].charAt(0)}`;
  }, []);

  const _renderChannel = (channelType: ChannelType) => {
    if (channelType === 'line') return <img alt="line" src={Line} />;
    if (channelType === 'facebook')
      return <img alt="facebook" src={Facebook} />;
    return <img alt="amity-chat" width={24} height={24} src={AmityLogo} />;
  };

  const _rendermemberStatus = (memberStatus: string) => {
    return (
      <CircleStatus
        width={width}
        height={height}
        data-testid="member-status"
        memberStatus={memberStatus}
      />
    );
  };

  const _renderAvatar = () => {
    if (img) {
      return <AvatarItem src={img}></AvatarItem>;
    }
    if (name) {
      return (
        <AvatarItem
          fontSize={fontSize}
          style={{ background: matchFirstLetterToColor(name) }}
        >
          {getSplitName(name)}
        </AvatarItem>
      );
    }
    return (
      <AvatarItem
        icon={
          <FontAwesomeIcon
            style={{ width: width / 2, height: height / 2 }}
            icon={['fal', 'user']}
          />
        }
      />
    );
  };

  return (
    <Container width={width} height={height}>
      {_renderAvatar()}
      {channelType && <div>{_renderChannel(channelType)}</div>}
      {memberStatus && <div>{_rendermemberStatus(memberStatus)}</div>}
    </Container>
  );
};
