import styled from 'styled-components/macro';
import { Input } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { generateHeight } from '../../utils/inputHeight';

export const AntdTextInput = styled(Input)``;

export const Container = styled.div<{ size: SizeType }>`
  input {
    height: ${({ size }) => generateHeight(size)};
    border: 1px solid ${({ theme }) => theme.palette.GREY_7};
    box-shadow: none !important;
    box-sizing: border-box;
    border-radius: 8px !important;
  }

  input:focus,
  input:hover {
    border: 1px solid ${({ theme }) => theme.palette.GREY_7};
  }
`;
