import styled from 'styled-components/macro';
import { Checkbox as AntdCheckbox } from 'antd';

export const Checkbox: typeof AntdCheckbox = styled(AntdCheckbox)`
  align-items: center;
  .ant-checkbox .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
  }

  /* checked */
  .ant-checkbox-checked .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    background: ${({ theme }) => theme.palette.PRIMARY};
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    border: none !important;
  }
  .ant-checkbox-checked::after {
    border: none !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    margin-left: 1.5px;
  }

  .ant-checkbox {
    top: 0;
  }

  .ant-checkbox:hover {
    border: none;
  }

  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: white;
  }
  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.7),
        rgba(255, 255, 255, 0.7)
      ),
      #04be8c;
  }
`;

export const CheckBoxGroup = styled(AntdCheckbox.Group)`
  width: 100%;
`;
