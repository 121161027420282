import { DeepPartial, Theme } from '../../types/theme';

export const theme: DeepPartial<Theme> = {
  palette: {
    PRIMARY: '#04BE8C',
    PRIMARY_HOVER: '#01CA94',
    SECONDARY: '#F5F5F5',
    WHITE: '#FFFFFF',
    BLACK: '#000000',
    GREY_1: '#212121',
    GREY_2: '#424242',
    GREY_3: '#616161',
    GREY_4: '#757575',
    GREY_5: '#9E9E9E',
    GREY_6: '#BDBDBD',
    GREY_7: '#E5E5E5',
    GREY_8: '#EEEEEE',
    GREY_9: '#F5F5F5',
    GREY_10: '#FAFAFA',
    BLUE: '#0F86FE',
    BLUE_LIGHT: '#E7F2FE',
    GREEN: '#29CB72',
    GREEN_LIGHT: '#E9F7EB',
    YELLOW: '#FFB400',
    YELLOW_LIGHT: '#FFF4D9',
    RED: '#F72C40',
    RED_LIGHT: '#FFEAEE',
    GREY_DARK: '#242731',
    CYAN: '#05BED2',
    CYAN_LIGHT: '#E6F8FA',
    VIOLET: '#5856D6',
    VIOLET_LIGHT: '#EEEEFA',
    PINK: '#E61E73',
    PINK_LIGHT: '#FCE8F1',
    ORANGE: '#FF742F',
    ORANGE_LIGHT: '#FFF1EA',
  },
};
