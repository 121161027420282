import styled from 'styled-components/macro';
import { Menu as AntdMenu, Dropdown as AntdDropdown } from 'antd';

export const Container = styled.div`
  width: 100%;
`;
export const Dropdown: typeof AntdDropdown = styled(AntdDropdown)``;
export const IconWrap = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  color: #757575;
  svg {
    width: 16px;
  }
`;
export const Menu = styled(AntdMenu)`
  overflow: auto;
  border-radius: 12px;
  padding-top: 12px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%) !important;
  min-width: 240px;
  max-height: 400px;
  & li {
    height: 44px;
    display: flex;
    align-items: center;
    padding: 16px;
    cursor: pointer;
    font-weight: 500;

    img {
      margin-right: 8px;
      height: 24px;
      width: 24px;
    }
  }

  & li:last-child {
    margin-bottom: 12px;
  }

  & li:hover {
    background: ${({ theme }) => theme.palette.GREY_9};
    color: initial;
  }
`;

export const MenuItem = styled(Menu.Item)``;

export const GroupName = styled(Menu.Item)`
  height: 44px;
  padding: 16px;
  background: transparent !important;
  cursor: initial !important;
  span {
    color: ${({ theme }) => theme.palette.GREY_5};
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
  }
`;
