import React, { ReactNode } from 'react';
import { Table as AntTable } from 'antd';
import { Container } from './style';

export type IColumns<T = any> = {
  title: string | ((props: any) => React.ReactNode);
  dataIndex: string;
  key: string;
  render?: (text: string, record: T, index: number) => ReactNode;
};

export type ITable<T = any> = {
  header: React.ReactNode;
  data: any;
  columns: IColumns<T>[];
  [key: string]: any;
};

export const Table: React.FC<ITable> = ({
  header,
  data,
  columns,
  ...rest
}: ITable) => {
  return (
    <Container>
      {header}
      <AntTable columns={columns} dataSource={data} {...rest} />
    </Container>
  );
};
