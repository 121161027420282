import styled from 'styled-components/macro';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { generateHeight } from '../../../utils/inputHeight';
import {
  Menu as AntdMenu,
  Dropdown as AntdDropdown,
  Button as AntdButton,
  Typography,
  Col,
} from 'antd';
import { SearchInput } from '../../SearchInput';

export const Container = styled.div<{ size: SizeType }>`
  width: 100%;
  button {
    width: 100%;
    display: flex;
    padding: 5px 12px;
    border: 1px solid ${({ theme }) => theme.palette.GREY_7};
    border-radius: 8px;
    height: ${({ size }) => generateHeight(size)};
    align-items: center;
    justify-content: space-between;
    color: ${({ theme }) => theme.palette.GREY_1} !important;
    border-color: ${({ theme }) => theme.palette.GREY_7} !important;
    & > div:nth-child(1) {
      flex: 1;
      display: flex;
      align-items: center;
    }
  }
`;

export const ItemGroupTitle = styled(Typography.Text)`
  font-size: 10px;
  font-weight: 600;
`;

export const SelectedItem = styled.div`
  display: flex;
  img {
    margin-right: 8px;
    height: 22px;
    width: 22px;
  }
`;

export const DropdownOptionLabel = styled(Typography.Text)`
  max-width: 200px;
`;

export const PlaceHolder = styled.div`
  color: ${({ theme }) => theme.palette.GREY_5};
`;

export const Menu = styled(AntdMenu)`
  overflow: auto;
  border-radius: 12px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%) !important;
  max-height: 500px;
  & .ant-menu-item-group-list .ant-menu-item {
    padding: 0 16px 0 16px;
  }
  .ant-dropdown-menu-item-group-list {
    margin: 0;
  }
`;
export const Item = styled(AntdMenu.Item)`
  padding: 0 !important;
  :hover {
    background: ${({ theme }) => theme.palette.GREY_9};
    color: initial;
  }
  .ant-row {
    min-height: 44px;
    margin: 0 !important;
    padding: 12px 16px;
  }
  .ant-col {
    padding: 0 !important;
    display: flex;
    align-items: center;
  }
`;
export const ItemGroup = styled(AntdMenu.ItemGroup)`
  .ant-dropdown-menu-item-group-title {
    line-height: 1;
    padding: 16px;
    color: #9e9e9e;
  }
`;

export const ItemGroupSeperator = styled.div`
  height: 1px;
  background: #e5e5e5;
  margin: 7.5px 16px;
`;

export const ImgCol = styled(Col)`
  display: flex;
  align-items: center;
`;

export const IconContainer = styled.div`
  img {
    vertical-align: middle;
    width: 24px;
    height: auto;
    margin-right: 8px;
  }
`;

export const Topbar = styled.div`
  user-select: none;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
`;

export const SelectAllWrap = styled.div`
  cursor: pointer;
  color: #0f86fe;
`;
export const ClearWrap = styled.div`
  cursor: pointer;
  color: #616161;
`;

export const Button: typeof AntdButton = styled(AntdButton)``;

export const ItemInner = styled.div`
  display: flex;
`;

export const DropdownSearchContainer = styled.div`
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 16px;
`;

export const DropDownSearch = styled(SearchInput)`
  background: ${({ theme }) => theme.palette.GREY_9};
  border: none;
  box-shadow: none !important;
  svg {
    color: rgb(158, 158, 158) !important;
  }
  &.ant-input-affix-wrapper > input.ant-input {
    background-color: ${({ theme }) => theme.palette.GREY_9};
  }
`;
