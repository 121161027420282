import { theme } from '../../configs/theme';

export const MIDDLE_BUTTON = `
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.4286;
  letter-spacing: -0.0125rem;
  height: 36px;

  &.ant-btn-icon-only {
    width: 2.25rem;
  }
`;

export const LARGE_BUTTON = `
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: -0.0125rem;
  height: 48px;

  &.ant-btn-icon-only {
    width: 3rem;
  }
`;
